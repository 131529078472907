<template>
  <div>
    <navigation-bar @search="searchCommunity" class="nav" :searchValue="this.value">
      <template #nleft>
        <div class="leftimg" @click="goback">
            <van-icon name="arrow-left" size="18" />
        </div>
      </template>
      <template #nright>
        <div class="rightImg" @click="popupShow">
          <img
              src="../assets/image/addpost.png"
              alt=""
          />
        </div>
      </template>
    </navigation-bar>

      <div class="box" v-if="dataList.length=== 0 ">
          <div class="kong">
              <img src="~@image/net-sch-default.png" alt="">
              <p>暂无搜索结果,请换个搜索词汇试试吧</p>
          </div>
      </div>
     
        <van-list
            v-model="loading"
            :finished="finished"
            @load="onLoad"
            finished-text="没有更多了"
        v-else>
         <ul style="margin-top:45px">
          <post-list2 v-for="(item,index) in dataList"
                     :key="item.id"
                       @jumpDetail="jumpDetail(item)"
                      @showOperation="showOperation(item,index)"
                       @handleshare="handleshare(item)"
                     :item="item"/>
        </ul> 
        </van-list>

    <van-popup v-model="showPopup" round position="bottom" >
      <div class="popup_item" @click="addPost(1)">
        <img src="../assets/image/post-type-1.png" alt=""/>
        <span>帖子</span>
      </div>
      <div class="popup_item" @click="addPost(2)">
        <img src="../assets/image/post-type-2.png" alt=""/>
        <span>功能BUG</span>
      </div>
      <div class="popup_item" @click="addPost(3)">
        <img src="../assets/image/post-type-3.png" alt=""/>
        <span>投诉建议</span>
      </div>
    </van-popup>

    <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'" v-if="isshow!=true"  v-model="operation" position="bottom">
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect !== true" v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)"  v-else v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postShield(dataItem,dataIndex)">
              <img src="../assets/image/post-shield.png" alt=""/>
              <span>屏蔽</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postReport(dataItem)">
              <img src="../assets/image/post-report.png" alt=""/>
              <!-- 投诉 -->
              <span>举报</span>
            </div>
    </van-popup>

    <van-popup :class="dataItem.postType !==3 || isshow != true ?'popup-operation':'popup_active'" v-else v-model="operation" position="bottom" >
           <div :class="dataItem.postType !==3 || isshow != true ?'popup_item':''" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect != true" v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)"  v-else v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="editor(dataItem)">
              <img src="../assets/image/My-bianji.png" alt=""/>
              <span>编辑</span>
            </div>
            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="delReport(dataItem,dataIndex)">
              <img src="../assets/image/My-del.png" alt=""/>
              <span>删除</span>
            </div>
    </van-popup>

  </div>
</template>

<script>
import NavigationBar from "./common/NavigationBar";
import PostList2 from "./community/PostList2";
import {findCommunity,Collection,removeCollect} from "../api/community";
import { delArticle,delBugOrSuggest} from '../api/user';
import qs from "qs"

export default {
  name: "Community2",
  data() {
    return {
      loading: true,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      dataList: [],
      //发布内容
      showPopup: false,
      //更多
      operation: false,
      value:'',
      dataItem:'',
      isshow:false
    }
  },
  components: {
    NavigationBar,
    PostList2
  },
  methods: {
    goback(){
      this.$router.go(-1)
    },
    //编辑
          editor(item){
             sessionStorage.setItem("pageType","1")
             this.$router.push(`/addposts2/${item.postType}?id=${item.id}`)
          },
   
    async del(item,index){
           const info = {
                dismainId:item.id
           } 
          const res = await delArticle(info)
          if(res.code === 0 ){
              this.$toast("删除成功！")
              this.dataList.splice(index,1)
          }    
        },
        async delbug(item,index){
            const info = {
               id : item.id
            }
            const res = await delBugOrSuggest(qs.stringify(info))
             if(res.code === 0 ){
              this.$toast("删除成功！")
              this.dataList.splice(index,1)
             }    
        },
         //删除
        delReport(item,index){
            this.operation = false
            Dialog.confirm({
              title: '删除',
              message: '是否删除该帖子吗？',
              confirmButtonText:'删除',
              confirmButtonColor:'#4588FF',
            })
              .then(() => {
                // on confirm
               if(item.postType == 1 || item.postType == 2){
                  this.del(item,index)
               }else{
                  this.delbug(item,index)
               }
              })
              .catch(() => {
                // on cancel 
              });
       },

    searchCommunity(value){
      this.getdetails(value)
    },

     onLoad() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.finished = false
      this.loading = true
      this.pageNum ++
      this.getdetails(this.value)
    },

    async getdetails(value){
        const data = {
          pageNum:1,
          pageSize:10,
          search:value,
          type:0
        }
        const result = await findCommunity(data)
        if(result.code === 0 ){
           this.dataList  = []
           if (result.data.list.length === 0) {
              this.loading = false
              this.finished = true
           }else{
              this.$toast.clear()
              this.loading = false
              this.finished = true
              this.dataList = result.data.list
           }

            if (result.data.isLastPage) {
              this.finished = true
            }
         
        }
    },
    //分享
    handleshare(item){
      let txt = "http://"+window.location.host+"/#/CommunitDetails?id="+item.id
      if(window.android){
            const obj = {
              action:'share',
              data:{
                type:3,
                content:txt,
              }
          }
          console.log(obj)
          window.android.postMessage(JSON.stringify(obj));
      }else if(window.webkit){
         // ios 环境下
        const obj = {
          action: "share",
          data: {
            type: 3,
            content:txt,
          },
        };
        let jsonStr = JSON.stringify(obj);
         console.log(jsonStr)
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      }
    },
      //收藏
      async postCollect(item,index){
         let obj = ""
         obj = item
         const info = {
           collectType:1,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast(result.msg)
           this.operation = false
           this.dataList[index].collect = true
         }
      },
      postReport(item){
         this.$router.push({
           name:'complaint',
           query:{
              id:item.id,
              type:item.postType
           }
         })
      },
      //取消收藏
      async removeCollect(item,index){
         const info = {
           targetId:item.id,
           type:item.postType
         }
         const result = await removeCollect(qs.stringify(info))
         if(result.code === 0){
           this.$toast("取消收藏!")
           this.operation = false
           this.dataList[index].collect = false
         }else{
           this.$toast(result.msg)
           this.operation = false
         }
      },

      //屏蔽
      async postShield(item){
         let obj = ""
         obj = item
         const info = {
           collectType:2,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast("屏蔽成功！")
           this.operation = false
           this.$router.go(0)
         }
      },
    popupShow(){
      this.showPopup = true;
    },
    addPost(type){
      this.$router.push(`/addposts2/${type}`)
    },
    showOperation(item,index){
      this.operation = true
      this.dataItem = item
      this.dataIndex = index
         let userInfo = JSON.parse(localStorage.getItem("userInfo"))
       if(item.userid == userInfo.userId){
         this.isshow = true
       }else{
         this.isshow = false
       }
    },
      jumpDetail(item){
            this.$router.push({
              name:'CommunitDetails',
              query:{
                id:item.id,
                type:item.postType,
                tabType:this.active,
                pageType:1,
              }
            })
        },
  },
  created() { 
    this.pageNum = 1
    this.value = sessionStorage.getItem("value")
    this.getdetails(this.value)
  },
}
</script>

<style scoped lang="scss">
.nav{
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 100;
}
.leftimg {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rightImg{
  margin-left: 15px;
  width: 22px;
}

.van-popup{
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-operation{
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_item{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
}
.popup_item > img{
  width: 20px;
  height: 20px;
}
.popup_item > span{
  margin-left: 20px;
}
.box{
  height: 772px;
  display: flex;
  justify-content: center;
  align-items: center;
  .kong{
     text-align: center;
     img{
       width: 138px;
       height: 112px;
     }
     p{
       margin-top: 15px;
       font-size: 12px;
       font-family: PingFang SC;
       font-weight: 500;
       color: #999999;
     }
  }
}
.popup_active{
  height:20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .active_item{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 20px;
    flex: 1;
    img{
      width: 20px;
      height: 20px;
       transform: translateY(-2px);
    }
    span{
      margin-left: 20px;
    }
  }
}
</style>